import { Controller } from '@hotwired/stimulus';
import { Toast } from 'rad_common_js/src/toast';

export default class extends Controller {
  static values = { urlTemplate: String, targetSelector: String };

  connect() {
    if (this.hasTargetSelectorValue) {
      this.targetElement = document.querySelector(this.targetSelectorValue);
    }
  }

  async onChange() {
    if (!this.targetElement) {
      return;
    }
    const url = this.constructUrl(this.element.value);
    this.clearTargetOptions();
    if (url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          Toast.error('An error occurred while fetching data.');
          return;
        }

        const data = await response.json();
        this.addOptionsToTarget(data);
        this.targetElement.dispatchEvent(new Event('change'));
      } catch {
        Toast.error('An error occurred while fetching data.');
      }
    }
  }

  clearTargetOptions() {
    const tomSelect = this.targetElement.tomselect;
    tomSelect.clear();
    tomSelect.clearOptions();
    tomSelect.addOption({ value: '', text: 'None' });
    tomSelect.setValue('');
  }

  addOptionsToTarget(data) {
    const tomSelect = this.targetElement.tomselect;
    const options = data.map((item) => ({ value: item.id, text: item.name }));
    tomSelect.addOptions(options);
    tomSelect.setValue('');
  }

  constructUrl(id) {
    if (id && id !== '') {
      return this.urlTemplateValue.replace(/:\w+_id/, id).replace(/%3A\w+_id/, id);
    }
    return null;
  }
}
