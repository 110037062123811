import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  checkAll() {
    this.toggleAll(true);
  }

  uncheckAll() {
    this.toggleAll(false);
  }

  toggleAll(checked) {
    this.checkboxTargets.forEach((checkbox) => {
      if (!checkbox.disabled) {
        checkbox.checked = checked;
        checkbox.form.requestSubmit();
      }
    });
  }
}
