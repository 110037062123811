import AdjustedTimesController from './adjusted_times_controller';
import ApproveChargesController from './approve_charges_controller';
import FileUploadController from './file_upload_controller';
import PaymentsController from './payments_controller';
import RecentTasksController from './recent_tasks_controller';
import RemoteSelectController from './remote_select_controller';
import TimerController from './timer_controller';

export const controllers = [
  { id: 'adjusted-times', controller: AdjustedTimesController },
  { id: 'approve-charges', controller: ApproveChargesController },
  { id: 'file-upload', controller: FileUploadController },
  { id: 'payments', controller: PaymentsController },
  { id: 'recent-tasks', controller: RecentTasksController },
  { id: 'remote-select', controller: RemoteSelectController },
  { id: 'timer', controller: TimerController },
];
